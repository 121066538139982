<template>
  <div class="container">
    <div class="czyyz" >
      <el-popover
        v-model="visible"
        trigger="manual"
        placement="bottom"
        width="400"
      >
        <div class="popups">
          <div class="nav">
            <div
              style="
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
                z-index: 30000;
              "
            ></div>
            <template v-for="item in list">
              <div
                v-if="item.isMenu"
                :class="[
                  'Navigation-box',
                  item.id == 7 || item.id == 8 ? 'height-144' : 'height-100',
                ]"
                :key="item.id"
              >
                <div class="title" @click="navigation(item)">
                  <span></span>
                  <span>{{ item.authName }}<span v-if="redList.includes(item.authName)" class="red" style="margin-left:6px" >·</span></span>
                  <img :src="item.imgSrc" />
                </div>
                <div class="Navigation-list">
                  <template v-for="i in item.children">
                    <div
                      v-if="i.isMenu"
                      :key="i.id"
                      :class="[
                        'Nav-item',
                        item.id == 7 || item.id == 8
                          ? 'height-25'
                          : 'height-33',
                      ]"
                      @click="topage(i)"
                    >
                      <span>{{ i.authName }}</span>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
        <span slot="reference" class="NavigationBut">导航</span>
      </el-popover>
    </div>
  </div>
</template>
<script>
let redList = ["智能招聘","智能培训","智能绩效","智能薪酬","AI人力"];
import convertTree from "@/utils/convertTree";
import router from '@/router';
export default {
  name: "AllNavigationPage",
  watch: {
    "$store.state.personageList": {
      immediate: true,
      handler(val) {
        this.list = [];
        if (val) {
          this.list = JSON.parse(JSON.stringify(val));
          let parent = this.list.filter((i) => {
            if (i.pid == null) {
              return i;
            }
          });
          parent.forEach((i) => {
            convertTree(this.list, i);
          });
          parent = parent.filter(i => {
                if (i.children.length > 0) {
                  return true;
                } 
              })
          this.list = parent;
        }
   
      },
    },
  },
  data() {
    return {
      redList:Object.freeze(redList),
      id: null,
      list: [],
      navigation1: [
        { id: 1, name: "APP" },
        { id: 2, name: "微信公众号" },
        { id: 3, name: "iCome" },
        { id: 4, name: "微信/QQ群" },
        { id: 5, name: "小程序" },
        { id: 6, name: "网页Web" },
        { id: 7, name: "呼叫中心" },
      ],
      visible: true,
    };
  },
  
  methods: {
    // 跳转入对应模块第一个children的页面,如没有子页面，则return
    navigation(path){
      console.log("path:-",path);
      
      if(path.id == 5){
        this.$router.push("/Workbench/Index")
        return
      }
      if(path.children[0].name){
        this.$store.commit("SET_headStr", path.children[0].name);
        this.$router.push({name:path.children[0].name})
      }else {
        return
      }
    },
    //子菜单
    topage(item) {
      console.log("item:-",item);
      if (item.data) {
      } else if (item.type) {
        this.$store.commit("SET_headIndex", item.type);
        this.$router.push(item.path);
      } else if (item.web) {
        window.open(i.web);
      } else {
        this.$store.commit("SET_headStr", item.name);
        this.$router.push(item.path);
      }
    },
    towebpage(url) {
      window.location.href = url;
    },
    mouseover() {
      // this.visible = true;
    },
  },
};
</script>
<style lang="less">
.czyyz{
  .el-popover {
  top: 40px !important;
  width: 960px !important;
  background-color: #f4f5f9;
}

}

.popups {
  position: relative;
  // height: 650px;
  padding-bottom: 0.125rem;

  .nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .nav-home {
      cursor: pointer;
      width: 5%;
      padding: 10px;
      padding-left: 0;
      text-align: center;
      font-weight: 700;

      //background-color: #fae9e9;
      border-radius: 6px;
    }

    .height-144 {
      height: 128px !important;
    }

    .height-100 {
      min-height: 100px !important;
    }

    .Navigation-box {
      display: flex;
      width: 49%;
      height: 72px;
      margin-bottom: 10px;
      background-color: #ffffff;
      border-radius: 6px;

      .title {
        //background-color: #fae9e9;
        display: flex;
        cursor: pointer;
        //justify-content: center;
        //align-items: center;
        flex-direction: column;
        width: 23%;
        //cursor: pointer;
        padding: 10px;
        padding-left: 20px;
        border-radius: 6px;
        font-size: 18px;

        img {
          width: 38px;
        }

        span {
          margin-bottom: 10px;
          font-weight: 700;
          font-size: 14px;
        }
      }

      .Navigation-list {
        width: 77%;
        //background-color:#fafcff;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;

        .height-25 {
          height: 23.5% !important;
        }

        .height-33 {
          height: 32.5% !important;
        }

        .Nav-item {
          font-size: 16px;
          height: 50%;
          width: 32%;
          cursor: pointer;
          border: #fafcff 2px solid;
          border-radius: 4px;
          text-align: center;

          span {
            font-size: 12px;
          }
        }

        .kongbai {
          width: 32%;
          //height: 50%;
        }

        .Nav-item:hover {
          border: #f4f5f9 2px solid;
        }
      }
    }
  }
}

.closeBtn:hover {
  color: #f85d5d;
  border: #f85d5d 1px solid;
}

.closeBtn {
  position: absolute;
  z-index: 100000000000000000;
  cursor: pointer;
  top: 20px;
  width: 25px;
  height: 25px;
  right: 20px;
  background-color: white;
  line-height: 25px;
  border-radius: 50%;
  text-align: center;
  box-shadow: 2px 2px 10px #a1a1a1;
}

.NavigationBut {
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;

  i {
    margin-right: 5px;
  }
}
</style>
<style lang="less" scoped>
.container {
  width: 100%;

  // 原导航高度
  //height: calc(100% - 40px);
  /*height: calc(100% - 51px);
        padding: 20px;*/
  /*原导航样式*/
  .el-row {
    height: 100%;

    .el-col {
      /*background-color: #fae9e9;*/
      height: 100%;

      .top-title {
        color: white;
        height: 40px;
        line-height: 40px;
        background-color: #f85d5d;
        text-align: center;
        margin-bottom: 10px;
      }

      .navigation {
        cursor: pointer;
      }

      .navigation:hover {
        background-color: #fae9e9;
      }

      .navigation-box {
        height: calc(100% - 50px);
        border: #f85d5d 1px solid;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .navigation-item {
          /*text-align: center;*/
          /*padding: 10px 5px;*/
          padding-left: 5px;
        }
      }

      .box2 {
        height: calc(100% - 50px);
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .height-13-5 {
          height: 13.5%;
        }

        .subtitle {
          border: #f85d5d 1px solid;
          text-align: center;
        }

        .subtitle-box {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .small-box {
            width: 30%;
            border: #f85d5d 1px solid;
            border-radius: 6px;
            text-align: center;
          }
        }
      }

      .box3 {
        height: calc(100% - 50px);
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .height-19-5 {
          height: 19.5%;
        }

        .navigation-box3 {
          display: flex;
          justify-content: space-between;

          .subtitle {
            width: 13.5%;
            border: #f85d5d 1px solid;
            text-align: center;
          }

          .subtitle-item {
            width: 13.5%;
            border: #f85d5d 1px solid;
            border-radius: 6px;
            text-align: center;
          }
        }
      }

      .text {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.red{
    color: red;

}
</style>
